import React, { useRef, useState, useEffect } from "react"
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { RowContainer, LabelTitle } from "./LabelTitle"

// import { Button } from "../ui/button"
import { useStore } from "../../lib/states"
import { useClickAway, useToggle } from "react-use"
// import { Textarea } from "../ui/textarea"
// import { cn } from "../../lib/utils"
// import { Slider } from "../ui/slider"

import * as SliderPrimitive from "@radix-ui/react-slider"

import { useHookStore } from "../../hook-store/store"
import { resizeImageFile } from '../../utils/image/image-utils';
import { marks } from '../../utils/marks';

import "./DiffusionOptionsImagePrompt.css";


const DiffusionOptionImagePrompt = () => {
  const [
    isProcessing,
    prompt,
    updateSettings,
  ] = useStore((state) => [
    state.getIsProcessing(),
    state.settings.prompt,
    state.updateSettings,
    state.showPrevMask,
    state.hidePrevMask,
  ])

  const [t] = useTranslation('translation');

  const hiddenFileInput = useRef(null);

  const [hStore, hDispatch] = useHookStore();
  const { 
    // serverProcessing,
    ipAdapterImage,
    ipAdapterScale,
    ipAdapterImages,
    loadedFiles,
   } = (hStore as any).iopaintStore;
  // const { 
  //   showToggleNav, 
  //   showInpaintSettings,
  //  } = (hStore as any).uiStore;

  const [showScroll, toggleShowScroll] = useToggle(false)

  const ref = useRef(null)
  useClickAway<MouseEvent>(ref, () => {
    if (ref?.current) {
      const input = ref.current as HTMLTextAreaElement
      input.blur()
    }
  })

  const [ipaFileDimension, setIpaFileDimension] = useState({ height: 0, width: 0 });
  const [showImagePromptInput, setShowImagePromptInput] = useState(false);

  useEffect(() => {
    if (loadedFiles && loadedFiles.imageFile && 
        loadedFiles.ipaImageFiles && loadedFiles.ipaImageFiles.length > 0 && 
        ipAdapterImages.length === 0 && loadedFiles.openApp === 'dreamInpainter'
    ) {
      setParamFilesHandler();
    }
  },[loadedFiles]);

  const ipaImageSelectHandler = async (event: any) => {

    if (event.target.files?.length > 0) {
      let resFile;

      resFile = await resizeImageFile(
        event.target.files[0],
        'file',
        event.target.files[0].type.split('/')[1],
        768,
        768,
      );
  
      console.log(resFile);
  
      if (resFile) {
        // setFile(resFile);
        (hDispatch as any)('SET_IPADAPTERIMAGE', resFile);
        (hDispatch as any)('SET_MODELNAME', 'ipa');
      } else {
        throw new Error('resize failed');
      }

      // (hDispatch as any)('SET_IPADAPTERIMAGE', event.target.files[0]);
      // (hDispatch as any)('SET_MODELNAME', 'ipa');
    }
  }

  const addIpaImageHandler = async (files: any) => {
    if (ipAdapterImages.length > 0) {
      return;
    }

    if (files?.length > 0) {
      let resFile;

      resFile = await resizeImageFile(
        files[0],
        'file',
        files[0].type.split('/')[1],
        512,
        512,
      );
  
      console.log(resFile);
  
      if (resFile) {
        // setFile(resFile);
        const ipaImages = ipAdapterImages;
        const addedImages = ipaImages.concat({ 
          id: Math.random(),
          image: resFile, 
          // ipaScale: 0.7,
          ipaScale: 1.0,
          addTime: Date.now(),
        });
        (hDispatch as any)('SET_IPADAPTERIMAGES', addedImages);
        (hDispatch as any)('SET_MODELNAME', 'ipa');
      } else {
        throw new Error('resize failed');
      }
    }
  };

  const setParamFilesHandler = async () => {
    try {
      await addIpaImageHandler(loadedFiles.ipaImageFiles);
      (hDispatch as any)('SET_LOADEDFILES', null);
      setShowImagePromptInput(true);
    } catch(err) {
      console.log(err);
    }
  };

  const handleInputClick = (event: any) => {
    (hiddenFileInput as any).current.click();
  };

  const ipaImageDeleteHandler = () => {
      (hDispatch as any)('SET_IPADAPTERIMAGE', null);
      (hDispatch as any)('SET_MODELNAME', '');
  }

  const removeIpaImageHandler = (id: any) => {
    const deletedList = ipAdapterImages.filter((ipaImage: any) => {
      return ipaImage.id !== id;
    });

    console.log(deletedList);

    (hDispatch as any)('SET_IPADAPTERIMAGES', deletedList);

    if (deletedList.length === 0) {
      (hDispatch as any)('SET_MODELNAME', '');
    }
  };

  const changeIpaImageScaleHandler = (vals: any, data: any) => {
    const deletedIpaImages = ipAdapterImages.filter((ipaImage: any) => {
      return ipaImage.id !== data.id;
    });

    const newData = {
      ...data,
      ipaScale: Number(vals[0]),
    }

    const newList = deletedIpaImages.concat(newData);
    
    const sortedList = _.sortBy(newList, ['addTime']);
    (hDispatch as any)('SET_IPADAPTERIMAGES', sortedList);

  }

  const showImagePromptInputHandler = () => {
    setShowImagePromptInput(!showImagePromptInput);
  };

  const getImgDimension = (event: any) => {
    // console.log(event, event.target.naturalHeight, event.target.naturalWidth);
    if (event.target.naturalHeight !== ipaFileDimension.height ||
        event.target.naturalWidth !== ipaFileDimension.width
    ) {
        // console.log(event, event.target.naturalHeight, event.target.naturalWidth);

        setIpaFileDimension({
          height: event.target.naturalHeight,
          width: event.target.naturalWidth,
        });
      }
  }


  let ipaImageListBody;

  if (!ipAdapterImages.length || ipAdapterImages.length === 0) {
    ipaImageListBody = (
      <div>No image</div>
    )
  } else {
    ipaImageListBody = (
      <ul>
        {ipAdapterImages.map((ipaImage: any) => {
          if (ipaImage.image) {
            return (
              <li key={ipaImage.id}>
                <div className="doImagePromptInputs">
                  <img className="doImagePromptInputImage"
                        style={{maxHeight: "150px"}}
                        src={URL.createObjectURL(ipaImage.image)}
                        // onLoad={getImgDimension}
                        alt="img prompt input"
                  />
                  <div>
                    <div>
                      {t('diffusionOptions12', 'Image intensity')}
                    </div>
                    <RowContainer>
                      <SliderPrimitive.Root
                        // ref={ref}
                        // className={cn(
                        //   "relative flex w-full touch-none select-none items-center",
                        //   className
                        // )}
                        className={
                          "relative flex touch-none select-none items-center"
                          + " w-[180px]"
                        }
                        tabIndex={-1}
                        defaultValue={[ipaImage.ipaScale]}
                        min={0.1}
                        max={1.0}
                        step={0.1}
                        // value={ipAdapterScale}
                        onValueCommit={(vals) => {
                          changeIpaImageScaleHandler(vals, ipaImage);
                        }}
                      >
                        <SliderPrimitive.Track className="relative h-1.5 w-full grow overflow-hidden rounded-full bg-primary/20 data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50">
                          <SliderPrimitive.Range className="absolute h-full bg-primary data-[disabled]:cursor-not-allowed " />
                          </SliderPrimitive.Track>
                          <SliderPrimitive.Thumb
                            tabIndex={-1}
                            className="block h-4 w-4 rounded-full border border-primary/60 bg-background shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring data-[disabled]:cursor-not-allowed"
                          />
                        </SliderPrimitive.Root>
                        <span
                          className={
                            "flex h-8 rounded-md bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                            + " w-[60px] rounded-full"
                          }
                        >
                          {ipaImage.ipaScale}
                        </span>
                      </RowContainer>
                  </div>
                </div>
                <div className="doImagePromptInputButton">
                  <button className="iopaintBtnBase"
                    onClick={() => { 
                      removeIpaImageHandler(ipaImage.id); 
                    }}
                  >
                    {marks.trashMark}{' '}
                    {t('diffusionOptions11', 'Delete image')}
                  </button>
                </div>
              </li>
            );
          }
        })}
      </ul>
    );
  }


  return (
      <div 
      // className="flex flex-col gap-1"
      >
        <span onClick={showImagePromptInputHandler}>
          <LabelTitle
            text="Image prompt (optional)"
            toolTip={`${t('diffusionOptions08', 'use your image as input for inpainting. It is recommended to use square image.')}
            ${' '}${t('diffusionOptions09', 'Image intensity controls strength of image input. Decreasing image intensity reduce effect of image and increse effect of text prompt.')}`}
          />
          <span>
            {' '}{marks.triangleDown}
          </span>
        </span>
        {(ipAdapterImages.length > 0 || showImagePromptInput) && (
          <div className="doImagePromptInputs">
            <div className="doImagePromptInputRow">
              <input 
                type="file"
                ref={hiddenFileInput}
                style={{display: 'none'}}
                // onChange={ipaImageSelectHandler}
                onChange={(event) => { 
                  addIpaImageHandler(event.target.files); 
                }}
                accept="image/png, image/jpeg"
              />
              <button 
                style={ipAdapterImages.length > 0 ? {display: "none"} : {display: "block"}}
                className="iopaintBtnBase" 
                // disabled={}
                onClick={handleInputClick}
              >
                {marks.fileImageOMark} Select image file (jpeg or png file)
                {/* {marks.televisionMark} {t('resizer01', 'Select device images (max 10 files)')} */}
              </button>
            </div>

            <div>
              {ipaImageListBody}
            </div>
            {/* {!ipAdapterImage && (
              <div>
                No image
              </div>
            )}
            {ipAdapterImage && (
              <div className="doImagePromptInputRow">
                <img className="doImagePromptInputImage"
                  style={{maxHeight: "150px"}}
                  src={URL.createObjectURL(ipAdapterImage)}
                  onLoad={getImgDimension}
                  alt="img prompt input"
                />
                <div className="doImagePromptInputButton">
                  <button className="iopaintBtnBase"
                    onClick={ipaImageDeleteHandler}
                  >
                    {marks.trashMark} Delete image file
                  </button>
                </div>
                <div>
                  <div>Image intensity</div>
                  <RowContainer>
                    <SliderPrimitive.Root
                      // ref={ref}
                      // className={cn(
                      //   "relative flex w-full touch-none select-none items-center",
                      //   className
                      // )}
                      className={
                        "relative flex touch-none select-none items-center"
                        + " w-[180px]"
                      }
                      tabIndex={-1}
                      defaultValue={[ipAdapterScale]}
                      min={0.1}
                      max={1.0}
                      step={0.1}
                      // value={ipAdapterScale}
                      onValueCommit={(vals) => {
                        (hDispatch as any)('SET_IPADAPTERSCALE', vals[0])
                      }}
                    >
                      <SliderPrimitive.Track className="relative h-1.5 w-full grow overflow-hidden rounded-full bg-primary/20 data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50">
                        <SliderPrimitive.Range className="absolute h-full bg-primary data-[disabled]:cursor-not-allowed " />
                        </SliderPrimitive.Track>
                        <SliderPrimitive.Thumb
                          tabIndex={-1}
                          className="block h-4 w-4 rounded-full border border-primary/60 bg-background shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring data-[disabled]:cursor-not-allowed"
                        />
                      </SliderPrimitive.Root>
                      <span
                        className={
                          "flex h-8 rounded-md bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                          + " w-[60px] rounded-full"
                        }
                      >
                        {ipAdapterScale}
                      </span>
                    </RowContainer>
                  </div>
              </div>
            )} */}

          </div>
        )}

      </div>
  )
}

export default DiffusionOptionImagePrompt
